import React from "react"
import BannerGoogleWorkspace from "../components/BannerGoogleWorkspace"
import ToggleVisibility from "../components/ToggleVisibility"
import Faq from "react-faq-component"
import "./faq.css"

const data = {
  title: "",
  rows: [
    {
      id: 1,
      title: "Si funksionon bashkëpunimi në kohë reale?",
      content:
        "Mund të përdorni me ose pa internet aplikacionet Docs, Sheets, Slides. Punoni në të njejtin material dhe në të njetin moment  me kolegët tuaj ose me njerëz jashtë kompanisë. Ndryshimet që bëhen ruhen në kohë reale.",
    },
    {
      id: 2,
      title: "Si kryhet ruajtja e të dhënave dhe shpërndarja e tyre në cloud?",
      content:
        "Ruani të gjithë punën tuaj në një vend te vetëm dhe të sigurt. Informacioni juaj mund të jetë i aksesueshëm nga PC, Smartphone dhe tablet. Shumë lehtë mund të ftoni të tjerët të shikojnë,të shkarkojnë dhe të bashkëpunojnë me materialet e ndryshme, pa pasur nevojën e attachment. Dokumentet ruhen automatikisht në Drive dhe kushdo që ka akses mund te shikojë versionin e fundit të saj.",
    },
    {
      id: 3,
      title: "Siguria e të dhënave dhe pajisjeve tuaj?",
      content:
        "Mbroni të dhënat e kompanise tuaj me opsionin  2-step verification, single-sign-on dhe endpoint management që edhe në rast se ju humb një pajisje ose ndonjë punonjës ju largohet nga puna ju keni mundësi të rekuperoni materialet. Arkivoni mesazhet në email-in tuaj për sa kohë ju doni. Suport i vazhdueshem nga ana jonë.",
    },
  ],
}
const data2 = {
  title: "",
  rows: [
    {
      id: 4,
      title: "Google Workspace është një shërbim pajtimi me pagesë?",
      content:
        "Shumë njerëz janë konfuzë midis Google Workspace dhe aplikacioneve të tjera falas të Google të ndërtuara gjithashtu përreth Google Drive. Duhet të mbani mend që Google Workspace eshte nje zgjidhje e pershtatshme per biznesin tuaj dhe kështu përfshin shumë Sherbime / Features që nuk janë pjesë e aplikacioneve falas të konsumatorëve Google edhe pse emrat mund të jenë të njëjtë.",
    },
    {
      id: 5,
      title: "Benefitet e përdorimit të Google Workspace?",
      content:
        "Reduktoni shpenzimet për IT deri në 30% në vit, duke nisur nga ky vit dhe jo në tjetrin. Punoni nga cdo vend, në cdo pajisje dhe në cdo kohë. Rrisni dhe zgjeroni ekipin tuaj që punon në distancë. Sistemoni biznesin tuaj dhe delegoni më shumë. Mos u mbështesni në servera dhe VPN. Nuk do keni më nevojën e serverave për biznesin tuaj te vogël dhe te mesëm.",
    },
    {
      id: 6,
      title:
        "Përse duhet të blej paketat Google Workspace nga NMC, kur mund ta blej nga website zyrtar i Google?",
      content:
        "NMC Soft është Partner i Google për rajonin dhe një kompani që ofron shërbime teknologjike që nga viti 2008. NMC Soft përdor platformat e Google prej vitesh cfarë bën të mundur një përvojë të gjerë dhe ekspertizë në përdorimin e këtyre platformave. Si partner në rajon, NMC ju ofron paketa Google Workspace me një çmim favorizues. Me përvojën e saj NMC: Ju suporton në procesin e hapjes dhe mbylljen e llogarisë tuaj, Ju suporton në një panel të vetëm menaxhimi dhe suport për problematikat në lidhje me emailin.",
    },
  ],
}

const FAQ = () => {
  return (
    <>
      <div className="pt-16 lg:pt-32">
        <BannerGoogleWorkspace
          color="#F3F3F3"
          title="Gjeni përgjigjet që ju nevojiten"
          text="Pyetjet më të shpeshta"
        />
      </div>
      <div className="pt-16 lg:pt-28 container mx-auto">
        <section className="center ">
          <Faq
            data={data}
            styles={{
              bgColor: "white",
              titleTextColor: "#000000",
              rowTitleColor: "#000000",
              rowTitleTextSize: "18px",
              rowContentColor: "#000000",
              rowContentTextSize: "18px",
              rowContentPaddingTop: "53px",
              rowContentPaddingBottom: "53px",
              rowContentPaddingLeft: "80px",
              rowContentPaddingRight: "150px",
              arrowColor: "black",
            }}
          />
        </section>

        <ToggleVisibility>
          <section className="center">
            <Faq
              data={data2}
              styles={{
                bgColor: "white",
                titleTextColor: "#000000",
                rowTitleColor: "#000000",
                rowTitleTextSize: "18px",
                rowContentColor: "#000000",
                rowContentTextSize: "18px",
                rowContentPaddingTop: "53px",
                rowContentPaddingBottom: "53px",
                rowContentPaddingLeft: "80px",
                rowContentPaddingRight: "150px",
                arrowColor: "black",
              }}
            />
          </section>
        </ToggleVisibility>
      </div>
    </>
  )
}

export default FAQ
