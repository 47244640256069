import React from "react"
import classnames from "classnames"

/**
 *
 * @param {{ to: string, title: string, size: 'default' | 'large', external: boolean }} param0
 */
function Button({ to, title, disabled, size = "default", ...props }) {
  const className = classnames(
    "js-submitfbpixel w-full inline-block py-3 font-bold text-lg leading-6 hover:text-white hover-bg-gray-700 focus:bg-gray-700 focus:outline-none",
    {
      "px-8 py-2": size === "default",
      "px-16 py-5": size === "large",
      "bg-gray-900 text-white": !disabled,
      "bg-gray-900 text-white pointer-events-none": disabled,
    },
    props.className
  )
  return <button className={className} disabled={disabled} {...props} />
}

export default Button
