import React from "react"
import SingleCards from "./single_card"
import BannerGoogleWorkspace from "../components/BannerGoogleWorkspace"

const Cards = () => {
  React.useEffect(() => {
    import("react-gtm-module")
      .then(m => m.default)
      .then(TagManager => {
        var gtm = document.querySelectorAll(".js-gtm")
        var gtm1 = document.querySelectorAll(".js-gtm1")
        var gtm2 = document.querySelectorAll(".js-gtm2")

        gtm.forEach(button => {
          button.addEventListener("click", () => {
            const tagManagerArgs = {
              gtmId: "GTM-P2LR3BZ",
              events: {
                sendbuttonpackagedata: "Starter package",
              },
            }

            TagManager.initialize(tagManagerArgs)
            TagManager.dataLayer(tagManagerArgs)
          })
        })

        gtm1.forEach(button => {
          button.addEventListener("click", () => {
            const tagManagerArgs = {
              gtmId: "GTM-P2LR3BZ",
              events: {
                sendbuttonpackagedata: "Business package",
              },
            }

            TagManager.initialize(tagManagerArgs)
            TagManager.dataLayer(tagManagerArgs)
          })
        })

        gtm2.forEach(button => {
          button.addEventListener("click", () => {
            const tagManagerArgs = {
              gtmId: "GTM-P2LR3BZ",
              events: {
                sendbuttonpackagedata: "Enterprise package",
              },
            }

            TagManager.initialize(tagManagerArgs)
            TagManager.dataLayer(tagManagerArgs)
          })
        })
      })
    import("react-facebook-pixel")
      .then(m => m.default)
      .then(ReactPixel => {
        var gtm = document.querySelectorAll(".js-gtm")
        var gtm1 = document.querySelectorAll(".js-gtm1")
        var gtm2 = document.querySelectorAll(".js-gtm2")

        gtm.forEach(button => {
          button.addEventListener("click", () => {
            const advancedMatching = { em: "some@email.com" }
            const options = {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            }
            ReactPixel.init("1267235816773641", advancedMatching, options)
            ReactPixel.track("Package", {
              test: "Starter package",
            })
          })
        })

        gtm1.forEach(button => {
          button.addEventListener("click", () => {
            const advancedMatching = { em: "some@email.com" }
            const options = {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            }
            ReactPixel.init("1267235816773641", advancedMatching, options)
            ReactPixel.track("Package", {
              test: "Business package",
            })
          })
        })

        gtm2.forEach(button => {
          button.addEventListener("click", () => {
            const advancedMatching = { em: "some@email.com" }
            const options = {
              autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
              debug: false, // enable logs
            }
            ReactPixel.init("1267235816773641", advancedMatching, options)
            ReactPixel.track("Package", {
              test: "Enterprise package",
            })
          })
        })
      })
  }, [])

  return (
    <>
      <div className="lg:pt-24 ">
        <BannerGoogleWorkspace
          color="#F3F3F3"
          title="Gjej planin e duhur për biznesin tënd"
          text=" Sa kushton Google Workspace?"
        />
      </div>

      <div className="container mx-auto max-w-7xl pt-12 lg:pt-24  ">
        <div className=" ">
          <h1 className="text-center font-normal h-12 text-xl leading-7 not-italic mt-12">
            Ekzistojnë tre versione në dispozicion për ekipe dhe kompani të{" "}
            <br /> çdo madhësie. Google Workspace gjithashtu ofron një{" "}
            <span className="text-black inline-block font-semibold ">
              provë falas 14 ditore.
            </span>
          </h1>
        </div>
        <div id="cards" className="mt-12 lg:mt-24 ">
          <div className="container pt-24 pb-8 lg:pt-8 lg:pb-10">
            <div class=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5 ">
              <SingleCards
                title="Starter"
                price="5.75"
                data={[
                  "Adresë email-i e personalizuar. ",
                  "30GB memorie për çdo përdorues.",
                  "Video konferenca me 100 pjesëmarrës.",
                  "Siguria e të dhënave.",
                  "Suport të vazhdueshëm",
                ]}
                buttonclass="js-gtm bg-black group-hover:bg-white group-hover:text-black text-white py-4 px-9 md:py-2 md:px-4 md:text-base border border-gray-400  "
              />
              <SingleCards
                title="Business"
                price="11.50"
                data={[
                  "Adresë email-i e personalizuar. ",
                  "2TB memorie për çdo përdorues.",
                  "Video konferenca me mbi 150 pjesëmarrës + regjistrim",
                  "Siguria e të dhënave.",
                  "Suport të vazhdueshëm",
                ]}
                buttonclass="js-gtm1 bg-black group-hover:bg-white group-hover:text-black text-white py-4 px-9 md:py-2 md:px-4 md:text-base border border-gray-400  "
              />
              <SingleCards
                title="Enterprise"
                price="17.25"
                data={[
                  "Adresë email-i e personalizuar. ",
                  "5TB memorie për çdo përdorues.",
                  "Video konferenca me mbi 500 pjesëmarrës + regjistrim",
                  "Siguria e të dhënave.",
                  "Suport të vazhdueshëm",
                ]}
                buttonclass="js-gtm2 bg-black group-hover:bg-white group-hover:text-black text-white py-4 px-9 md:py-2 md:px-4 md:text-base border border-gray-400  "
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cards
