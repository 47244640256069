import React from "react"
function BannerGoogleWorkspace(props) {
  return (
    <>
      <div className="container mx-auto">
        <div
          className="w-full h-60 py-6 sm:py-10 lg:py-16 "
          style={{ backgroundColor: props.color }}>
          <div className="text-center mt-5">
            <div className="uppercase">{props.title}</div>
            <div className="mt-5 text-3xl">{props.text}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BannerGoogleWorkspace