import React from "react"

import { Formik, Form } from "formik"

import * as Yup from "yup"
import Input from "./Form/Input"
import TextArea from "./Form/TextArea"
import Button from "./Form2/Button"

import * as notification from "./Form/notification"
import { sendGoogleWorkspaceContactEmail } from "../emails/emails"

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("Vendosni emrin tuaj").label("Emri"),
  email: Yup.string()
    .email("Ju lutem vendosni një adresë të vlefshme emaili")
    .required("Vendosni emailin tuaj")
    .label("Email"),
  phoneNumber: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Numri juaj nuk eshte i vlefshem"
    )
    .label("Phone Number"),
  message: Yup.string().required("Shkruani mesazhin tuaj").label("Message"),
})

const ContactUsGoogleWorkspace = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <div
      id="contact-us"
      className="pt-20 lg:pt-32 py-10 md:py-16 container mx-auto"
    >
      <div className="w-full h-full mx-auto bg-gray-100 overflow-hidden py-12">
        <div className="md:flex">
          <div className="p-3 md:mt-16 ml-4 md:ml-10 md:w-1/2">
            <div className="uppercase mt-4 tracking-wide text-md   text-gray-900 font-medium">
              Kontakto për më shumë informacion
            </div>
            <div className="text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl mt-4">
              Na shkruaj ose kontakto me agjentin tonë
            </div>
            <div className="text-md md:text-md lg:text-lg xl:text-xl 2xl:text-2xl md:mt-36">
              Agjenti i shitjes: <a href="tel:+355696088845">+355 69 608 8845</a>
            </div>
          </div>
          <div className="p-8  md:shrink-0">
            <div className="h-full  w-full justify-end md:h-full md:w-80 xl:w-96 xl:ml-14 2xl:mt-14">
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phoneNumber: "",
                  message: "",
                }}
                validationSchema={ContactUsSchema}
                onSubmit={async (values, { resetForm }) => {
                  try {
                    setIsLoading(true)
                    const { data } = await sendGoogleWorkspaceContactEmail(
                      values
                    )

                    await import("react-facebook-pixel")
                      .then(m => m.default)
                      .then(ReactPixel => {
                        ReactPixel.init("1267235816773641")
                        ReactPixel.track("Contact", values)
                      })

                    await import("react-gtm-module")
                      .then(m => m.default)
                      .then(TagManager => {
                        const tagManagerArgs = {
                          gtmId: "GTM-P2LR3BZ",
                          events: {
                            contact: values,
                          },
                        }

                        TagManager.initialize(tagManagerArgs)
                        TagManager.dataLayer(tagManagerArgs)
                      })

                    if (data.message) {
                      notification.success(data.message)
                    }
                    setIsLoading(false)
                    resetForm()
                  } catch (error) {
                    setIsLoading(false)
                    notification.error("Something went wrong. Try again!")
                  }
                }}
              >
                {formikProps => {
                  return (
                    <Form>
                      <Input
                        name="name"
                        type="text"
                        label="Emri"
                        placeholder="Vendos emrin tënd"
                        className="border-none"
                      />
                      <Input
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Vendos email-in tënd"
                        className="border-none"
                      />
                      <Input
                        name="phoneNumber"
                        type="text"
                        label="Phone Number"
                        placeholder="Vendos numrin tënd"
                        className="border-none"
                      />
                      <TextArea
                        name="message"
                        label="Mesazhi"
                        placeholder="Shkruaj pyetjet ose mesazhin tënd."
                        className="py-3 border-none"
                      />
                      <Button disabled={isLoading} type="submit">
                        {isLoading ? "Loading..." : "Dërgo mesazh"}
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUsGoogleWorkspace
