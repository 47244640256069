import React from "react"

import Seo from "../components/seo"
import GoogleWorkspaceFirstSection from "../components/GoogleWorkspaceFirstSection"
import Cards from "../components/cards"
import ContactUsGoogleWorkspace from "../components/ContactUsGoogleWorkspace"
import GwApps from "../components/GwApps"
import Faq from "../components/FAQ"
import SupportCard from "../components/SupportCard"

const GoogleWorkspace = () => {
  React.useEffect(() => {
    import("react-facebook-pixel")
      .then(m => m.default)
      .then(ReactPixel => {
        const advancedMatching = {}
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        }
        ReactPixel.init("451083693535721", advancedMatching, options)

        ReactPixel.pageView() // For tracking page view
      })

    import("react-gtm-module")
      .then(m => m.default)
      .then(TagManager => {
        const tagManagerArgs = {
          gtmId: "GTM-TRS7LDZ",
          events: {
            sendUserInfo: "userInfo",
          },
        }

        TagManager.initialize(tagManagerArgs)
        TagManager.dataLayer(tagManagerArgs)
      })
  }, [])

  return (
    <>
      <Seo
        title="Google Workspace Albania"
        description="NMC Soft is a certified Google Partner in Albania.
      We provide google services for companies that need to organize better their online presence. Google Workspace is a service provided from Google and brought to you from NMC.
      Google Workspace offers different solutions in one place like Gmail, Google Meet, Google Drive, Google Calendar, Google Sheets, Cloud Hosting etc, with a 24-hour
      support from Google Certified Engineers."
      />
      <GoogleWorkspaceFirstSection />
      <GwApps />
      <Cards />
      <Faq />
      <ContactUsGoogleWorkspace />
      <SupportCard />
      <div className="fixed z-30 bottom-0 left-0 right-0 mb-8">
        <div class="container mx-auto">
          <div className="flex  justify-end">
            <a
              className="w-14 h-14 p-1.5 flex items-center justify-center border border-black/25 bg-white shadow-lg rounded-full transition-all transform duration-150 hover:shadow-xl hover:-translate-y-0.5 hover:scale[102]"
              href="https://api.whatsapp.com/send?phone=355696088845&text=P%C3%ABrshendetje!"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
                viewBox="0 0 39 39"
              >
                <path
                  fill="#00E676"
                  d="M10.7 32.8l.6.3c2.5 1.5 5.3 2.2 8.1 2.2 8.8 0 16-7.2 16-16 0-4.2-1.7-8.3-4.7-11.3s-7-4.7-11.3-4.7c-8.8 0-16 7.2-15.9 16.1 0 3 .9 5.9 2.4 8.4l.4.6-1.6 5.9 6-1.5z"
                ></path>
                <path
                  fill="#FFF"
                  d="M32.4 6.4C29 2.9 24.3 1 19.5 1 9.3 1 1.1 9.3 1.2 19.4c0 3.2.9 6.3 2.4 9.1L1 38l9.7-2.5c2.7 1.5 5.7 2.2 8.7 2.2 10.1 0 18.3-8.3 18.3-18.4 0-4.9-1.9-9.5-5.3-12.9zM19.5 34.6c-2.7 0-5.4-.7-7.7-2.1l-.6-.3-5.8 1.5L6.9 28l-.4-.6c-4.4-7.1-2.3-16.5 4.9-20.9s16.5-2.3 20.9 4.9 2.3 16.5-4.9 20.9c-2.3 1.5-5.1 2.3-7.9 2.3zm8.8-11.1l-1.1-.5s-1.6-.7-2.6-1.2c-.1 0-.2-.1-.3-.1-.3 0-.5.1-.7.2 0 0-.1.1-1.5 1.7-.1.2-.3.3-.5.3h-.1c-.1 0-.3-.1-.4-.2l-.5-.2c-1.1-.5-2.1-1.1-2.9-1.9-.2-.2-.5-.4-.7-.6-.7-.7-1.4-1.5-1.9-2.4l-.1-.2c-.1-.1-.1-.2-.2-.4 0-.2 0-.4.1-.5 0 0 .4-.5.7-.8.2-.2.3-.5.5-.7.2-.3.3-.7.2-1-.1-.5-1.3-3.2-1.6-3.8-.2-.3-.4-.4-.7-.5h-1.1c-.2 0-.4.1-.6.1l-.1.1c-.2.1-.4.3-.6.4-.2.2-.3.4-.5.6-.7.9-1.1 2-1.1 3.1 0 .8.2 1.6.5 2.3l.1.3c.9 1.9 2.1 3.6 3.7 5.1l.4.4c.3.3.6.5.8.8 2.1 1.8 4.5 3.1 7.2 3.8.3.1.7.1 1 .2h1c.5 0 1.1-.2 1.5-.4.3-.2.5-.2.7-.4l.2-.2c.2-.2.4-.3.6-.5s.4-.4.5-.6c.2-.4.3-.9.4-1.4v-.7s-.1-.1-.3-.2z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default GoogleWorkspace
