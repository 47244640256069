import React from "react"
import PrimaryLinkButton from "./PrimaryLinkButton"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Video from "../videos/workspace_homepage.mp4"

const GoogleWorkspaceFirstSection = () => {
  const data = useStaticQuery(graphql`
    {
      googleCloud: file(relativePath: { eq: "google-cloud-partner.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      drymades: file(relativePath: { eq: "drymades.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      businessmag: file(relativePath: { eq: "businessmag.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      farmaon: file(relativePath: { eq: "farmaon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      bioju: file(relativePath: { eq: "bioju.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      stina: file(relativePath: { eq: "stina.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <div className="container mx-auto flex flex-col">
      <div className="py-12 sm:grid  md:grid-cols-2">
        <div className="">
          <div className="mb-6 lg:mb-4 lg:mt-36 ">
            <h1 className=" text-4xl  font-semibold md:text-5xl  lg:w-13/16 leading-tight mb-8">
              Zgjidhja ideale për biznesin tuaj.
            </h1>
            <p className="max-w-4xl text-lg leading-snug md:text-2xl font-medium text-gray-700 pt-2">
              Google Workspace integron gjithcka që keni nevojë në një vend të
              vetëm, email nga google, video-konference, kalendar, paketa e
              google drive etj.
            </p>
          </div>
          <div className="flex md:pb-10 mt-24">
            <a
              href="https://cloud.withgoogle.com/partners/detail/?id=new-media-communications&hl=en-US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GatsbyImage
                image={data.googleCloud.childImageSharp.gatsbyImageData}
                className="h-auto w-40 md:w-48 block"
                alt="Google Cloud Partner"
              />
            </a>

            <PrimaryLinkButton
              to="/googleworkspace/#cards"
              title="Porosit Tani"
              size="large"
            />
          </div>
        </div>
        <div className="hidden md:block lg:block ">
          <video loop autoPlay muted>
            <source
              src={Video}
              type="video/mp4"
              alt="Google Workspace Albania"
            />
          </video>
        </div>
      </div>
      <div>
        <div className="mb-8  px-8 container bg-white py-4 md:py-8 lg:py-12">
          <div className="text-center">
            <div className="text-xl  mb-8  ">
              <div>
                <span className="font-bold px-2"> 500 + </span>
                kompani zgjedhin
                <span className="font-bold px-2"> Google Workspace </span>
                pranë NMC Soft
              </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-5 lg:grid-cols-5 gap-3 ">
              <GatsbyImage
                alt="google partner"
                image={data.drymades.childImageSharp.gatsbyImageData}
              />
              <GatsbyImage
                alt="google partner"
                image={data.farmaon.childImageSharp.gatsbyImageData}
              />
              <GatsbyImage
                alt="google partner"
                image={data.bioju.childImageSharp.gatsbyImageData}
              />
              <GatsbyImage
                alt="google partner"
                image={data.businessmag.childImageSharp.gatsbyImageData}
              />
              <GatsbyImage
                alt="google partner"
                image={data.stina.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default GoogleWorkspaceFirstSection
