import React from "react"
import BrainIcon from "../svg/brain.svg"
import SettingsIcon from "../svg/setting.svg"
import SupportServer from "../svg/server.svg"
import EmailIcon from "../svg/mail.svg"
import DashboardIcon from "../svg/dashboard.svg"
const icons = {
  brain: BrainIcon,
  setting: SettingsIcon,
  server: SupportServer,
  email: EmailIcon,
  panel: DashboardIcon,
}

const SupportItems = ({ iconName, label, description }) => {
  const Icon = icons[iconName] || "div"
  return (
    <div className="flex flex-col items-center  ">
      <Icon className="w-8 h-8 block fill-current text-black mt-2 mb-2" />
      <h1 className="text-black hover:text-blue-700 text-center text-sm font-bold mt-2 ">
        {label}
      </h1>
    </div>
  )
}

function SupportCard() {
  return (
    <div>
      <div className="mt-2 lg:mt-2  ">
        <div className="container pt-2 pb-8 lg:pt-8 lg:pb-10">
          <h3 className="pt-12 text-3.5xl font-semibold leading-tight  md:mb-4 text-center mb-2  ">
            Suporti nga NMC
          </h3>
          <span className="flex justify-center text-base md:mb-4  mb-2 ">
            Me inxhinier të çertifikuar nga Google
          </span>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:flex justify-center  lg:space-x-10
          ">
            <SupportItems
              iconName="setting"
              label="Suport 24/7"
              className="text-left"
            />
            <SupportItems
              iconName="server"
              label="Migrimi i lehtë i të dhënave"
            />
            <SupportItems iconName="panel" label="Panel i vetëm menaxhimi" />
            <SupportItems iconName="email" label="Email@yourcompany.com" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupportCard
