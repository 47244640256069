import React from "react"
import Gmail from "../svg/gmail.svg"
import Meet from "../svg/meet.svg"
import Calendar from "../svg/calendar.svg"
import Docs from "../svg/docs.svg"
import Sheets from "../svg/sheets.svg"
import Drive from "../svg/drive.svg"

const iconsgw = {
  meet: Meet,
  gmail: Gmail,
  calendar: Calendar,
  docs: Docs,
  sheets: Sheets,
  drive: Drive,
}

const SingleApp = ({ iconName, label, description }) => {
  const Icongs = iconsgw[iconName]
  return (
    <div className="flex flex-col items-center mt-6 mb-4">
      <Icongs className="w-14 h-14 block " />
      <p className="text-sm lg:text-lg font-bold mt-4">{label}</p>
      <p className=" w-2/4 text-md  mt-2 text-center ">{description}</p>
    </div>
  )
}
function GwApps() {
  return (
    <div className="mt-12 lg:mt-24 ">
      <div className="container pb-8 lg:pt-8 lg:pb-10">
        <h3 className="text-3xl md:text-2xl lg:text-3.5xl text-center font-semibold leading-tight mb-8 md:mb-4 ">
          Aplikacionet që përfshihen
        </h3>
        <div className="mt-2 md:mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:justify-between">
          <SingleApp
            iconName="gmail"
            label="Gmail"
            alt="Gmail"
            description="E-mail i personalizuar dhe profesional në Google."
          />
          <SingleApp
            iconName="meet"
            label="Meet"
            alt="Meet"
            description="Video konferenca me mbi 100 pjesëmarrës."
          />
          <SingleApp
            iconName="calendar"
            label="Calendar"
            alt="Calendar"
            description="Planifikoni konferencat dhe menaxhoni kohën tuaj."
          />
          <SingleApp
            iconName="docs"
            label="Docs"
            alt="Docs"
            description="Punoni, ruani dhe shpërndani dokumentat shumë thjeshtë."
          />
          <SingleApp
            iconName="sheets"
            label="Sheets"
            alt="Sheets"
            description="Krijoni, përpunoni dhe analizoni spreadsheets shume lehtë online."
          />
          <SingleApp
            iconName="drive"
            label="Drive"
            alt="Drive"
            description="Ruani dhe klasifikoni të dhënat tuaja lehtësisht në Cloud. "
          />
        </div>
      </div>
    </div>
  )
}

export default GwApps
