import { Link } from "gatsby"
import React from "react"

function SingleCards(props) {
  

  

  return (
    <>
      <div className="group ">
        <div className="block bg-white group-hover:bg-black border border-gray-200 transition duration-200 ease-in md:py-16 lg:py-16 xl:py-24 h-full">
          <div className="w-full">
            <div>
              <p className="text-4xl md:text-3xl leading-normal text-center font-semibold  text-black group-hover:text-white mt-5">
                {props.title}
              </p>
            </div>
            <div class="w-full md:flex justify-center text-center text-blue-500 group-hover:text-white mt-8 mb-2">
              <span className="text-6xl md:text-5xl">{props.price}</span>
              <span className="text-3xl">€</span>
            </div>
            <div className="justify-center text-center mt-5">
              <span className="ml-1 text-lg font-semibold text-black group-hover:text-white md:text-base">
                /perdorues/në muaj
              </span>
            </div>
            <Link to='/googleworkspace/#contact-us'> 
            <div className="py-6 text-center">
            
            <button  className={ props.buttonclass }>
             Zgjidh Paketen
            </button>
        </div>
        </Link>
        </div>
          <div className="flex border-t-2 border-gray-200 text-center md:w-40 md:ml-5 lg:w-52 lg:ml-7 xl:w-72 xl:ml-9"></div>
            <div className="flex  ml-10 md:ml-2 lg:ml-10 xl:ml-12 mt-12 ">
              <table>
                <thead>
                  {props.data.map(items => (
                    <tr>
                      <td>
                        {" "}
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          className="w-7 h-7 text-blue-500 group-hover:text-white mb-0 md:mb-2 lg:mt-2 lg:mb-1 xl:mt-3 2xl:mb-2"
                        >
                          <path d="M5 13l4 4L19 7"></path>
                        </svg>
                      </td>
                      <td>
                        <span className="text-start text-md md:text-xs xl:text-lg 2xl-text-xl text-black group-hover:text-white">
                          {items}
                        </span>
                      </td>
                    </tr>
                  ))}
                </thead>
              </table>
            </div>
          </div>
        </div> 
    </>
  )
}

export default SingleCards
