import React, { useState } from "react"
import ExpandArrow from "../svg/expand-arrow.svg"

export default function ToggleVisibility({ children }) {
  const [show, setShow] = useState()

  function toggleShow() {
    setShow(!show)
  }

  var buttonText = show ? "Lexo me pak" : "Lexo të gjitha"

  return (
    <div className="">
      {show && children}
      <div className="flex justify-end mt-8">
        <button
          className="text-gray-900 font-bold text-base p-1 border-none"
          onClick={toggleShow}
        >
          {buttonText}
        </button>
        <ExpandArrow className="ml-2 mt-1 font-bold" />
      </div>
    </div>
  )
}
